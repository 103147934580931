<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>
    <!-- add .theme2 to the container below to change the theme -->
    <section v-else class="main main_padding-top bg-white " style="min-height: 100vh;">
  

      <div class=" py-sm-4 py-4" id="order">
          <div class="rc-root">
              <div class="Page">
                  <div class="Page__body Page__width">
                      <div class=" row NewOrder">
                        <div class=" custom__body col-sm-8 col-12 p-5 NewOrder__main">
                            <div class="NewOrder__header ">
                                <div class="NewOrder__header__left text-left">
                                    <div class="NewOrder__heading py-2">Edit Order ({{$route.params.id}})</div>
                                    <div class="NewOrder__subheading">
                                      <span>It’s fast, secure, and confidential</span>
                                    </div>
                                </div>
                                <div class="NewOrder__header__right text-sm right text-left py-sm-0 py-3 ">
                                  <router-link :to="{ name: 'Order', params: { id: order.id } }"   class="UIButton_default_ghost " type="submit">Go Back to Order </router-link>
                                    
                                </div>
                                  
                          </div>
                          <div class="NewOrder__body">
                              <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                  <div class="rc-orderform">
                                      <div 
                                      
                                      class="rc-orderform__step  "
                                      
                                      >
                                            <div class="rc-orderform__step-flag ">
                                              <span class="rc-orderform__step-flag__count ">1.</span>Paper details
                                            </div> 
                                            
                                              <div class="rc-orderform__step__body">
                                              <div class="rc-orderform__row  ">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left   ">Academic level</div>
                                              <div class="rc-orderform__feature-body  ">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                  <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                      <div  v-for="(level, index) in academic_levels"
                                                        :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                          <button 
                                                          :class="
                                                            orderForm.academic_level === level.id
                                                              ? 'active'
                                                              : ''
                                                          "
                                                          style=" min-width:120px!important;  "
                                                          @click="activateAcademicLevel(level)"
                                                          type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                              <div class="button-checkbox"></div>
                                                              <div class="radio-button__content">{{ level.desc }}</div>
                                                          </button>
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Type of paper</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <!-- <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.document_type"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                              <option
                                                                v-for="(item, index) in document_types"
                                                                :key="index + 'om'"
                                                                :value="item.id"
                                                              >
                                                                {{ item.desc }}
                                                              </option>
                                                            </select> -->
                                                            <vue-select
                                                              class="Select-multi-value-wrapper form-control-md"
                                                              label="desc"
                                                              v-model="orderForm.document_type"
                                                              placeholder="Start typing to search"
                                                              @input="calculatePrice"
                                                              :options="document_types"
                                                              :reduce="(item) => item.id"
                                                            ></vue-select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--discipline">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Discipline</div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div>
                                                          <div class="rc-validation-representer" data-invalid="false">
                                                              <div class="Select DisciplineControl__select Select--single is-searchable">
                                                                <!-- <select
                                                                  @change="calculatePrice"
                                                                  v-model="orderForm.subject_area"
                                                                  name="select-size-9"
                                                                  id="select-size-9"
                                                                  class="border_gray_200 form-control form-control-md"
                                                                >
                                                                  <option
                                                                    v-for="(item, index) in subject_areas"
                                                                    :key="index + 'om'"
                                                                    :value="item.id"
                                                                  >
                                                                    {{ item.desc }}
                                                                  </option>
                                                                </select> -->

                                                                <vue-select
                                                                  class="Select-multi-value-wrapper form-control-md"
                                                                  label="desc"
                                                                  v-model="orderForm.subject_area"
                                                                  placeholder="Start typing to search"
                                                                  @change="calculatePrice"
                                                                  :options="subject_areas"
                                                                  :reduce="(item) => item.id"
                                                                ></vue-select>
                                                              
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Topic <span class="text-danger" >*</span> </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input  v-model="orderForm.title" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter the title of your paper" required tabindex="0" >
                                                          <!-- <p
                                                            class="text-danger text-left my-1"
                                                            v-if="'title' in errors"
                                                          >
                                                            {{ errors.title }}
                                                          </p> -->
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Paper instructions<span class="text-danger" >*</span></div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <vue-editor
                                                            
                                                            v-model="orderForm.description"
                                                          ></vue-editor>
                                                          <!-- <textarea v-model="orderForm.description" class="PaperInstructionsControl_textarea UIInput border_custom--input UIInput-default UIInput-default--type-textarea UIInput-default--size-m UIInput-default--color-default UIInput-default--autosize UIInput-default--not-resizable" placeholder="Write anything you feel is important for the writer to consider. An outline, a grading scale, and other documents may be uploaded as additional materials." tabindex="0" style="overflow: hidden; overflow-wrap: break-word; min-height: 150px;"></textarea> -->
                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--add-materials">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Additional materials</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <FileUploader v-model="orderForm.files"></FileUploader>

                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                      <div class="rc-orderform__feature-heading  text-sm-right text-left">Paper format</div>
                                                      <div class="rc-orderform__feature-body"><div>
                                                          <div class="rc-validation-representer valid" data-invalid="false">
                                                            <v-select
                                                              label="desc"
                                                              v-model="orderForm.style"
                                                              @input="calculatePrice"
                                                              placeholder="Start typing to search"
                                                              :options="styles"
                                                              :reduce="(style) => style.id"
                                                            ></v-select>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                      <div class="rc-orderform__feature-heading  text-sm-right text-left">Language Style</div>
                                                      <div class="rc-orderform__feature-body  "><div>
                                                          <div class="rc-validation-representer valid" data-invalid="false">
                                                              <div  style=" overflow: auto !important;"  class="rc-radios rc-radios--theme-default vertical-on-small">
                                                                  <div
                                                                  v-for="(style, index) in language_styles"
                                                                  :key="index + 'om'" 
                                                                  class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                                      <button
                                                                      style=" min-width:120px!important;  "
                                                                      :class="
                                                                        orderForm.language_style === style.id
                                                                          ? 'active'
                                                                          : ''" 
                                                                          @click="activateLanguageStyle(style)"
                                                                      type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                                          <div class="button-checkbox"></div><div class="radio-button__content"> {{ style.desc }}</div>
                                                                      </button>
                                                                  </div>
                                                                  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--deadline">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left">Deadline</div>
                                              <div class="rc-orderform__feature-body">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                    <v-select
                                                      label="desc"
                                                      v-model="orderForm.urgency"
                                                      placeholder="Start typing to search"
                                                      @input="calculatePrice"
                                                      :options="urgency"
                                                      :reduce="(urg) => urg.id"
                                                    ></v-select>
                                                          
                                                  </div>
                                                  <!-- <div class="DeadlineDate DeadlineControl__estimated-deadline"><span>We'll send you the order for review by <span class="DeadlineDate__date">Oct 17, 2022 at 10:11 AM</span></span>. <button type="button" class="DeadlineDate__calendar-button">Show calendar</button></div> -->
                                                  </div>
                                              </div>
                                              
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__pages-spacing">
                                                      <div class="rc-orderform__pages-spacing__pages">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Pages</div>
                                                          <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer valid" data-invalid="false">
                                                                  <div class="rc-stepper">
                                                                      <button @click="updatePages(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                                      <input 
                                                                      @change="calculatePrice"
                                                                      v-model="orderForm.pages"
                                                                      name="pages"
                                                                      min="0"
                                                                      :max="number_of_pages.max_pages"
                                                                      type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                      <button @click="updatePages(1)" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                                  </div>
                                                              </div>
                                                              <div class="rc-orderform__pages-spacing__words">Aprox {{ no_of_words || "" }} words</div>
                                                          </div>
                                                      </div>
                                                      <div class="rc-orderform__pages-spacing__spacing">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Spacing</div>
                                                          <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer valid" data-invalid="false">
                                                                  <div class="rc-radios rc-radios--theme-default SpacingControl">
                                                                      <div 
                                                                      v-for="(space, index) in spacing"
                                                                          :key="index + 'om'"
                                                                          class="radio-button-wrapper ">
                                                                          <button
                                                                          
                                                                          :class="
                                                                            orderForm.spacing == space.id ? 'active' : ''
                                                                          "
                                                                          @click="activateSpacing(space)"
                                                                          type="button" 
                                                                          class="radio-button radio-button--n-single" tabindex="0">
                                                                              <div class="button-checkbox"></div>
                                                                              <div class="radio-button__content">{{space.desc}}</div>
                                                                          </button>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__sources-charts-slides">
                                              <div class="rc-orderform__row rc-orderform__row--slides">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left">Sources to be cited</div>
                                              <div class="rc-orderform__feature-body text-left">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                      <div class="rc-stepper">
                                                          <button  @click="decrementSources(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                          <input 
                                                          v-model="orderForm.no_sources"
                                                          name="no_sources"
                                                          min="0" 
                                                          max="999" type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                          <button @click="orderForm.no_sources++" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--slides">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left">Charts</div>
                                              <div class="rc-orderform__feature-body text-left">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                      <div class="rc-stepper">
                                                          <button @click="updateCharts(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                          <input @change="calculatePrice" v-model="orderForm.charts" min="0" max="999" type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                          <button @click="updateCharts(1)" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--charts">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">PowerPoint slides</div>
                                                  <div class="rc-orderform__feature-body text-left">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <div class="rc-stepper">
                                                              <button @click="updateSlides(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                              <input min="0" v-model="orderForm.slides" max="999" type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" @change="calculatePrice">
                                                              <button @click="updateSlides(1)" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--writer-category">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Writer category</div>
                                                  <div class="rc-orderform__feature-body  ">
                                                      <div class="rc-validation-representer CategoryOfWriterControl valid" data-invalid="false">
                                                          <div
                                                          style="overflow:auto!important;"
                                                          class="rc-radios rc-radios--theme-default vertical-on-small">
                                                              
                                                              <div
                                                              style="min-width: 180px!important;"
                                                                v-for="(pref, index) in writer_levels"
                                                                :key="index + 'om'"
                                                              class="radio-button-wrapper radio-button-wrapper">
                                                                  <button 
                                                                  :class="
                                                                    orderForm.writer_level.includes(pref.id)
                                                                      ? 'active'
                                                                      : ''
                                                                  "
                                                                  @click="activateWriterPref(pref)"
                                                                  type="button" class="radio-button radio-button--n-3 " tabindex="-1">
                                                                      <div class="button-checkbox"></div>
                                                                      <div class="CategoryOfWriterControl__item">
                                                                          <div class="CategoryOfWriterControl__item__price">{{pref.cost}}</div>
                                                                          <span class="CategoryOfWriterControl__item__title">{{ pref.title }}</span>
                                                                          <div class="CategoryOfWriterControl__item__body">{{pref.desc}}</div>
                                                                      </div>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                              </div>
                                          </div>
                                          <!-- {{authUser}} -->
                                          <div class="rc-orderform__step">
                                            <div 
                                            
                                            class="rc-orderform__step-flag">
                                              <span class="rc-orderform__step-flag__count">2.</span>Account
                                            </div>
                                            
                                              <div class="rc-orderform__step__body">
                                                  <div v-if="authUser && Object.keys(authUser).length > 0" class="rc-message success plate">Your email is <strong>{{ authUser.email }}</strong>
                                                  </div>
                                                  <div v-else class="Auth row " style="min-height: 0px;">
                                                    <div class="Auth__tabs text-left col-12">
                                                      <button  @click="customer_type = 'returning'"  type="button" class="Auth__tabs__item " 
                                                      :class="customer_type == 'returning' ? 'Auth__tabs__item--active' : '' ">Returning customer</button>
                                                      <button @click="customer_type = 'new'" type="button" class="Auth__tabs__item"
                                                      :class="customer_type == 'new' ? 'Auth__tabs__item--active' : '' "
                                                      >New customer</button>
                                                    </div>
                                                    <div class="Auth__body col-12">
                                                      <form  v-if="customer_type == 'returning'" @submit.prevent="doPlaceOrder('login')">
                                                        <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Email or ID</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <input v-model="loginForm.email" name="email" autocomplete="email" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <!-- {{show_password}} -->
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Password</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <div class="UIPasswordInput">
                                                                  <input v-model="loginForm.password" name="password" autocomplete="current-password" type="password" class="UIInput UIInput-default border_custom--input UIInput-default--type-password UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                  <button @click="togglePassword('password')" type="button" class="UIPasswordInput__button" title="Show password"></button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="Auth__bottom-buttons Auth__bottom-buttons--sign-in">
                                                            <div class="AuthSignIn__links">
                                                              <router-link  :to="{name: 'ForgotPassword'  } " class="Auth__link UIButton UIButton-default-clean UIButton-default-clean--size-m UIButton-default-clean--color-primary" type="button">Forgot password </router-link>
                                                            </div>
                                                            <button  class="UIButton UIButton-default-ghost UIButton-default-ghost--size-l UIButton-default-ghost--color-primary UIButton-default-ghost--accent" type="submit">Sign in </button>
                                                          </div>
                                                        </div>
                                                      </form>

                                                      <form  v-if="customer_type == 'new'" @submit.prevent="doPlaceOrder('register')">
                                                        <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">First Name</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <input v-model="registerForm.first_name" name="email" autocomplete="text" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Last Name</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <input v-model="registerForm.last_name" name="email" autocomplete="text" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Email</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <input name="email" v-model="registerForm.email" autocomplete="email" type="email" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Phone Number</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <input v-model="registerForm.phone" name="email" autocomplete="phone number" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <!-- {{registerForm.country}} -->
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Country</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <vue-select
                                                                  class="Select-multi-value-wrapper form-control-md"
                                                                  label="name"
                                                                  v-model="registerForm.country"
                                                                  placeholder="Start typing to search"
                                                                  @input="calculatePrice"
                                                                  :options="countryNames"
                                                                  :reduce="(item) => item.name"
                                                                ></vue-select>
                                                                <!-- <select
                                                                  v-model="registerForm.country"
                                                                  name="select-size-9"
                                                                  id="select-size-9"
                                                                  class="border_gray_200 form-control form-control-md"
                                                                >
                                                                  <option
                                                                    v-for="(item, index) in countryNames"
                                                                    :key="index + 'om'"
                                                                    :value="item"
                                                                  >
                                                                    {{ item.name }}
                                                                  </option>
                                                                </select> -->
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Password</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <div class="UIPasswordInput">
                                                                  <input v-model="registerForm.password" name="password" autocomplete="current-password" type="password" class="UIInput UIInput-default border_custom--input UIInput-default--type-password UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                  <button type="button" class="UIPasswordInput__button" title="Show password"></button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <!-- <div class="rc-orderform__row">
                                                            <div class="rc-orderform__feature-heading  text-sm-right text-left">Confirm Password</div>
                                                            <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer" data-invalid="false">
                                                                <div class="UIPasswordInput">
                                                                  <input v-model="registerForm.password_confirmation" name="password_confirmation" autocomplete="current-password" type="password" class="UIInput UIInput-default border_custom--input UIInput-default--type-password UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                  <button type="button" class="UIPasswordInput__button" title="Show password"></button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div> -->
                                                          <div class="Auth__bottom-buttons Auth__bottom-buttons--sign-in">
                                                            
                                                            <button type="submit" class="UIButton UIButton-default-ghost UIButton-default-ghost--size-l UIButton-default-ghost--color-primary UIButton-default-ghost--accent">Create account & Sign in </button>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class=" col-sm-4 col-12 NewOrder__sidebar">
                                  <div  class="rc-sticky-sidebar" >
                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                          <div class="OrderformCheckoutInfo" role="presentation">
                                              <div class="OrderformCheckoutInfo__zig-zag-top"></div>
                                                  <div  class="OrderformCheckoutInfo__container ">
                                                  <div class="NewOrder__subheading py-2 ">
                                                    <!-- <span class="text-primary font-weight-bolder" >{{site_settings.name }}&nbsp;</span> -->
                                                    
                                                  </div>
                                                  <div class="OrderformCheckoutInfo__order-topic">{{ orderForm.title }}&nbsp;</div>
                                                  <div class="OrderformCheckoutInfo__order-short-info">
                                                      <div class="OrderformCheckoutInfo__order-short-info__item">{{ academic_level }}</div>
                                                      <div class="OrderformCheckoutInfo__order-short-info__item">{{ subject_area }}</div>
                                                      <div class="OrderformCheckoutInfo__order-short-info__item">{{ document_type }}</div>
                                                      <!-- <div class="OrderformCheckoutInfo__order-short-info__item">{{ academic_level }}</div> -->
                                                  </div>
                                                  <div class="InvoiceServices">
                                                      <div 
                                                      v-for="(price, index) in getPrice"
                                                      :key="index + 'test'"
                                                      class="InvoiceServices__item">
                                                          <div class="OrderDetails__info-row__label">
                                                              <span>
                                                                  <span>{{ price.title || "" }}</span> 
                                                                  <!-- <span class="OrderPagesSpacingPopover"></span> -->
                                                              </span>
                                                          </div>
                                                          <div class="InvoiceServices__item__value">
                                                              <span>${{ price.value || "0" }}</span>
                                                          </div>
                                                      </div>
                                                      <!-- <div class="InvoiceServices__item">
                                                          <div class="OrderDetails__info-row__label">Category of the writer</div>
                                                          <div class="InvoiceServices__item__value">
                                                              <span>$2.50</span>
                                                          </div>
                                                      </div> -->
                                                      <!-- <div class="InvoiceServices__item InvoiceServices__item--total-price">
                                                          <div class="InvoiceServices__item__heading">Total price</div>
                                                          <div class="InvoiceServices__item__value">
                                                              <span>$12.50</span>
                                                          </div>
                                                      </div> -->
                                                  </div>
                                                      <div class="OrderformCheckoutInfo_promocode d-flex flex-column align-items-start justify-content-start ">
                                                      <small class="text-start" >Enter the coupon code .</small>
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <div class="PromoCode__wrapper">
                                                              <div class="PromoCode__input">
                                                                  <input v-model="orderForm.coupon_code"  type="text" class="UIInput UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default w-100 UIInput-default--oneline" placeholder="Coupon Code" tabindex="0" @input="calculatePrice"></div>
                                                                  <!-- <button class="PromoCode__submit UIButton UIButton-default-filled UIButton-default-filled--size-m UIButton-default-filled--color-primary UIButton-default-filled--disabled" aria-disabled="true" tabindex="-1" disabled="" type="button">Apply </button> -->
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div v-if="coupon" class="alert alert-success my-1">
                                                        <span v-html="coupon || ''"></span>
                                                      </div>
                                                      <div
                                                        v-if="message"
                                                        class="alert my-2"
                                                        :class="
                                                          message.type ? 'alert-success' : 'alert-danger'
                                                        "
                                                      >
                                                        <span v-html="message.message || ''"></span>
                                                      </div>
                                                      <div class="OrderformCheckoutInfo__submit-button-container">
                                                          <button  @click="doPlaceOrder('checkout')" class="OrderformCheckoutInfo__checkout-button UIButton UIButton-default-filled UIButton-default-filled--size-l UIButton-default-filled--color-primary" type="button">Safe checkout </button>
                                                      </div>
                                                  </div>
                                                  <div class="OrderformCheckoutInfo__zig-zag-bottom"></div>
                                                  <div class="payment_methods m-2 widget_payments_security">
                                                      <div>
                                                          <span class="h4">Payment Methods:</span>
                                                      </div>
                                                      
                                                      <div class="payments_security_badges">
                                                          <div class="payments_security_badge_item"></div>
                                                          <div class="payments_security_badge_item"></div>
                                                      </div>
                                                  
                                                  <div>
                                                      <div class="CheckoutInfoAddOn__payment_methods"></div>
                                                      <div class="CheckoutInfoAddOn__mcafee">
                                                          <span title="McAfee SECURE" target="_blank" class="McAfee__image"></span>
                                                      </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <footer class="mini-auth-page__footer">
                      <div class="mini-auth-page__footer__links">
                          <a rel="nofollow noopener noreferrer" target="_blank" href="/" class="mini-auth-page__footer__link">Home</a>
                          <!-- <a rel="nofollow noopener noreferrer" target="_blank" href="/contact-us" class="mini-auth-page__footer__link">Contact US</a> -->
                          <a rel="nofollow noopener noreferrer" target="_blank" href="/orders/login" class="mini-auth-page__footer__link">Login</a>
                          <a rel="nofollow noopener noreferrer" target="_blank" href="/orders/signup" class="mini-auth-page__footer__link">Sign up</a>
                      </div>
                      <div class="mini-auth-page__footer__meta">
                          <div class="mini-auth-page__footer__address">
                              <div class="mini-auth-page__footer__copyrights">© 2022 {{site_settings.url }}. All Rights Reserved.</div>
                              <span title="TRUSTe Privacy Certification" target="_blank" rel="noopener noreferrer">
                                  <img src="https://assignmentsace.com/static/images/seal.svg" alt="TRUSTe" style="border: medium none;"></span>
                              </div>
                          </div>
                      </footer>
                          
                      </div>
                  </div>
    </section>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import mcafee from "../../assets/global_assets/images/mcafee.png";
import { mapGetters, mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import FileUploader from "../../components/formcontrols/FileUploader.vue";

export default {
  name: "PlaceOrder",
  components: {  FileUploader , 'vue-select': vSelect },
  computed: {
    ...mapGetters("setups", [
      "academic_levels",
      "document_types",
      "language_styles",
      "number_of_pages",
      "spacing",
      "styles",
      "subject_areas",
      "urgency",
      "writer_levels",
      "additional_services",
      "defaults",
    ]),
    ...mapGetters("auth", ["isAuthenticated", "authUser"]),
    ...mapGetters("order", ["getPrice"]),
    ...mapState("order", ["message", "coupon", "order"]),
    ...mapState("writers", ["writers"]),
    ...mapState("setups", ["countries", "site_settings"]),

    no_of_words() {
      return (
        275 *
          this.orderForm.pages *
          this.spacing.find((x) => x.id == this.orderForm.spacing).factor || 1
      );
    },

    subject_area() {
      return (
        this.subject_areas.find((x) => x.id == this.orderForm.subject_area)
          .desc || ""
      );
    },
    document_type() {
      return (
        this.document_types.find((x) => x.id == this.orderForm.document_type)
          .desc || ""
      );
    },
    academic_level() {
      return (
        this.academic_levels.find((x) => x.id == this.orderForm.academic_level)
          .desc || ""
      );
    },

    pages_message() {
      let message = "";
      if (
        this.orderForm.pages < 1 &&
        this.orderForm.charts < 1 &&
        this.orderForm.slides < 1
      ) {
        message = "You need to order at least 1 page, 1 slide or 1 chart";
      } else message = "";
      return message;
    },
  },

  data() {
    return {
      mcafee: mcafee,
      customer_type: "new",
      is_loading: false,
      orderForm: new this.$Form({
        id: "",
        academic_level: "",
        subject_area: "",
        language_style: "",
        urgency: "",
        document_type: "",
        spacing: "",
        writer_level: "",
        style: 1,
        additional_services: [],
        pages: 1,
        no_sources: 0,
        charts: 0,
        slides: 0,
        title: "",
        description: "",
        state: "",
        speciality: "",
        files: [],
        writer_id: "",
        coupon_code: "",
      }),

      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
      }),
      loginForm: new this.$Form({
        email: "",
        password: "",
      }),
    };
  },
  async mounted() {
    this.$store.state.loading = true;
    await this._getOrder(this.$route.params.id)
      .then(() => (this.$store.state.loading = false))
      .catch(() => {
        this.$router.push({ name: "404" });
      });
    this.getSiteSettings();
    await this._getSetups();
    await this.getWriters();
    await this._getCoupon();
  },
  methods: {
    // ...mapActions("setups", ["getSetups", "getCountries"]),
    ...mapActions("setups", ["getSetups","getSiteSettings", "getCountries"]),

    ...mapActions("auth", ["doLogin", "doRegister"]),
    ...mapActions("order", [
      "_calculatePrice",
      "_updateOrder",
      "_getCoupon",
      "_getOrder",
    ]),
    ...mapActions("writers", ["getWriters"]),
    _getSetups() {
      this.getSetups()
        .then(() => {
          // set order form
          Object.assign(this.orderForm, { ...this.order });
          this.orderForm.pages = this.order.no_pages;
          this.orderForm.slides = this.order.no_slides;
          this.orderForm.charts = this.order.no_charts;
          this.orderForm.additional_services =
            this.order.additional_services.map((item) => {
              if (item[0] >= 0) {
                return item[0];
              }
            });

          this.orderForm.files = [];
          delete this.orderForm.buttons;
          delete this.orderForm.client;
          delete this.orderForm.additional_services_text;
          // this.orderForm.id = this.order.id;
          // this.orderForm.title = this.order.title;
          // this.orderForm.description = this.order.description;
          // this.orderForm.pages = this.order.pages;
          // this.orderForm.charts = this.order.charts;
          // this.orderForm.slides = this.order.slides;
          // this.orderForm.subject_area = this.order.subject_area;
          // this.orderForm.urgency = this.order.urgency;
          // this.orderForm.document_type = this.order.document_type;
          // this.orderForm.academic_level = this.order.academic_level;
          // this.orderForm.language_style = this.language_style;
          // this.orderForm.spacing = this.order.spacing;
          // this.orderForm.writer_level = this.order.writer_level;
          // this.orderForm.style = this.order.style;

          this.calculatePrice();
        })
        .catch((err) => {
          this.$router.push({ name: "503" });
          console.log(err);
        });
    },

    activateAcademicLevel(level) {
      this.orderForm.academic_level = level.id;
      this.calculatePrice();
    },
    activateLanguageStyle(style) {
      this.orderForm.language_style = style.id;
    },
    activateSpacing(space) {
      this.orderForm.spacing = space.id;
      this.calculatePrice();
    },
    activateWriterPref(pref) {
      this.orderForm.writer_level = pref.id;
      this.calculatePrice();
    },
    updatePages(option) {
      if (!option) {
        if (this.orderForm.pages > 0) {
          this.orderForm.pages--;
        }
      } else {
        this.orderForm.pages++;
        // if (this.orderForm.pages <= this.number_of_pages.max_pages) {
        // }
      }
      this.calculatePrice();
    },
    decrementSources() {
      if (this.orderForm.no_sources > 0) {
        this.orderForm.no_sources--;
      }
    },
    updateCharts(option) {
      if (!option) {
        if (this.orderForm.charts > 0) {
          this.orderForm.charts--;
        }
      } else {
        this.orderForm.charts++;
      }
      this.calculatePrice();
    },
    updateSlides(option) {
      if (!option) {
        if (this.orderForm.slides > 0) {
          this.orderForm.slides--;
        }
      } else {
        this.orderForm.slides++;
      }
      this.calculatePrice();
    },

    calculatePrice() {
      console.log("this.orderForm", this.orderForm);
      this.is_loading = true;
      this._calculatePrice(this.orderForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    uploadFiles(event) {
      this.orderForm.files = event.target.files;
    },

    doUpdateOrder() {
      this.is_loading = true;
      console.log("orderForm no_sources", this.orderForm.no_sources);
      this._updateOrder(this.orderForm)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.$router.push(
              `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${this.order.id}`
            );
            this.loading = false;
          } else {
            this.$notify({
              title: "Error",
              text: res.data
                ? res.data.Message
                : "Error while placing order, please try again",
              style: "danger",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
.is-card {
  position: sticky;
  top: 20px;
}

@media screen and (max-width: 768px) {
  label {
    text-align: left !important;
  }
}

.order-topic.text-left {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  // min-height: 4.5em !important;
  max-height: 4.5em !important;
}
.btn-group {
  overflow-x: scroll;
  width: 100%;
}
</style>