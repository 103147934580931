<template>
  <div class="form-group row mb-2">
    <div class="col-12">
      <div class="file-upload-container">
        <!-- <input
          type="file"
          @change="uploadFiles"
          @dragend="uploadFiles"
          class="form-control border-0 file-upload"
          id="inputEmail3"
          placeholder="Choose files to upload"
          multiple="multiple"
        /> -->
            
        <div class="rc-files-control" data-v-5d71dd3d="">
        <div style="display: flex !important; align-items: center !important; justify-content: start !important;" class="">
          <div class=" rc-ui-blocker rc-ui-blocker--size-m dropzone-description" data-v-5d71dd3d="">
            <label class="btn-outline-default" data-v-5d71dd3d="">
              <i class="fa fa-image" data-v-5d71dd3d=""></i> Upload File<input type="file" @dragend="uploadFiles"  @change="uploadFiles"  multiple="" style="display: none;" name="image" data-v-5d71dd3d="">
            </label>
            <span class="control-or" data-v-5d71dd3d="">or</span>
            <span class="control-drop-here" data-v-5d71dd3d="">Drop files here</span>
          </div>
        </div>
        </div>
        <div  v-if="files.length > 0" class="rc-files-control rc-ui-blocker rc-ui-blocker--size-m dropzone-description" data-v-5d71dd3d="">
          <div v-for="(file, index) in files" :key="index" class="mt-3  rounded" data-v-5d71dd3d="">
            <span class="font_5 my-1 d-flex" data-v-5d71dd3d="">Selected files: </span>
            <br data-v-5d71dd3d="">
            <span class="font_6 py-1 d-flex align-items-center justify-content-start " data-v-5d71dd3d="">
              <i @click="deleteFile(file)"  class="btn btn-xs text-danger px-1 cursor-pointer icon-trash" data-v-5d71dd3d=""></i>
              <p data-v-5d71dd3d="">{{ file.name }}</p><br data-v-5d71dd3d="">
            </span>
          </div>
        </div>
        <!-- <div class="mb-1 mx-1" v-if="files.length > 0">
          <i v-for="(file, index) in files" :key="index">
            <span
              @click="deleteFile(file)"
              class="text-danger m-1 cursor-pointer delete-file"
              ><span class="icon-trash"></span
            ></span>
            {{ file.name }}
            <br />
          </i>
        </div> -->
      </div>
    </div>
    <!-- <div class="col-12">
      <p class="m-1 text-primary text-left">
        <span class="icon-help"></span> Drag and drop files or click to select
        files then upload
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: [],
    };
  },
  methods: {
    uploadFiles(event) {
      let files = event.target.files;

      Object.keys(files).forEach((file) => {
        this.files.push(files[file]);
      });

      this.$emit("input", this.files);
    },

    deleteFile(fileToDelete) {
      let files = this.files.filter((file) => file.name !== fileToDelete.name);
      this.files = files;
      this.$emit("input", this.files);
    },
  },
};
</script>

<style lang="scss" scoped>
$borderColor: #bebebe;
  $borderColorLight: #e0e0e0;
  $borderColorPrimary: #3695eb;
  $textColor: #244559;
//file upload
.file-upload {
  color: transparent;
  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.file-upload::-webkit-file-upload-button {
  visibility: hidden;
  box-shadow: none !important;
}

.file-upload::before {
  content: "Upload Files";
  position: absolute !important;
  left: 8px;
  right: 12px;
  top: 1px;
  bottom: -11px;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid $borderColor;
  border-radius: 3px;
  padding: 5px 8px;
  margin-bottom: 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  color: $borderColorPrimary !important;

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px rgb(33 153 232 / 25%) !important;
  }
}

.file-upload:hover::before {
  border-color: $borderColor;
}

.file-upload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.rc-orderform__feature-body {
  padding-top: 5px;
}
//   @media only screen and (min-width: 1025px){
// .rc-orderform__feature-body {
//   flex-grow: 1;
//   margin-left: 170px;
// }
// }
.rc-ui-blocker {
  position: relative;
}
.rc-ui-blocker--size-m {
  min-height: 2.75rem;
}
.rc-files-control {
  background-color: #fffdf0;
  border: 1px dashed #c9cde4;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.rc-files-control .browse-button {
  margin-right: 5px;
}
.UIButton-default-light {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background: transparent none;
    background-color: transparent;
  background-color: transparent;
  border: 1px solid #c9cde4;
  cursor: pointer;
  display: inline-block;
  font: inherit;
    font-size: inherit;
    line-height: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
    margin-right: 0px;
  margin-right: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
}
.UIButton-default-light--color-primary {
  color: #fb8a44;
  background-color: #fbfbfb;
  border: 1px solid #c9cde4;
}
.UIButton-default-light--size-m {
  line-height: 1.4;
  padding: .34375rem .875rem;
  font-size: .9375rem;
  min-width: 34px;
  min-height: 34px;
  border-width: 1px;
}

.rc-files-control .control-or {
  margin-left: 15px;
  margin-right: 15px;
}
.rc-files-control .control-drop-here {
  color: rgba(0,0,0,0.6);
}

.btn-outline-default{
    border: 1px solid #C9CDE4!important;
    padding: 8px 12px !important;
    background-color:#FBFBFB!important;
    border-radius: 4px !important;
    cursor: pointer!important;
}

</style>